import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { ImagePass } from './ImagePass'

class HomePageImageQuery extends React.Component {
  state = {
    fcpLoaded: false,
  }
  /*
  componentDidMount() {

    const observer = new window.PerformanceObserver(list => {
        list.getEntries().forEach(({name, startTime}) => {
            console.log({name, startTime});

            // Need to report to a server here
            const data = {
                name,
                startTime
            };
            console.log('paint:',data)
            if (data.startTime) {

            }
        });
    });
    observer.observe({
        entryTypes: ['largest-contentful-paint']
    });

    //Test
    setTimeout(() => {
      this.setState({fcpLoaded: true})
    }, 0);

  }
  */
  render() {
    const { data } = this.props
    const image = data.wordpressPage.acf.background
    if (!image) return null
    return <ImagePass src={image} className="background" returnBase64={true} fcpLoaded={this.state.fcpLoaded} />
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query HomePageImageQuery {
        wordpressPage(path: {eq: "/"}) {
          acf {
            background {
              localFile {
                publicURL
                childImageSharp {
                  base64: sizes(base64Width: 1200, quality: 60) {
                    base64
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <HomePageImageQuery data={data} {...props} />}
  />
)
