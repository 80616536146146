import React from 'react';
import './PageHeader.scss';
import { Image } from '../Image';
import { Breadcrumbs } from '../Breadcrumbs';
import { decodeEntities } from '../../utils/helpers';
import HomePageImageQuery from '../Image/HomePageImageQuery'
import GatsbyLink from '../GatsbyLink'
import { ProductImage } from '../Products/ProductImage'

export const PageHeader = (props) => {
  const { headerTitle, headerSubTitle, headerBackgroundImage, location, link = null, above, below, featureImage } = props;
  return (
    <section className={`page-header${location.pathname === "/" ? ' home' : ''}`}>
        <div className="title">
          <div className="wrap">
            <div className="inner">
              {above && below &&
                <div className="product-feature">
                  {featureImage ?
                    <Image src={featureImage} position="relative" />
                  :
                    <ProductImage above={above} below={below}/>
                  }
                </div>
              }
              <div className="content">
                {location.pathname !== "/" && <Breadcrumbs location={location}/>}
                {headerTitle && <h1 dangerouslySetInnerHTML={{__html: decodeEntities(headerTitle)}} />}
                {headerSubTitle && <p dangerouslySetInnerHTML={{ __html: decodeEntities(headerSubTitle) }} />}
                {link && <GatsbyLink className="action" to={link.url} target={link.target}>{link.title}</GatsbyLink>}
              </div>
            </div>
          </div>
        </div>
        {location.pathname === "/" && <div className="image"><HomePageImageQuery className="background"/></div>}
        {headerBackgroundImage && location.pathname !== "/" && <div className="image"><Image src={headerBackgroundImage} className="background" /></div>}
    </section>
  )
}
